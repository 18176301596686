import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/root/MyNotes-Webapp/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Button from "@material-ui/core/Button";
import { Playground } from 'docz';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "hello-world"
    }}>{`Hello world`}</h1>
    <p><img alt="hive\u5143\u6570\u636E\u5E93" src="https://cvm.chenxixian.cn:4000/imgs/2020/05/98027061798c1aff.png" /></p>
    <p>{`Hello, I'm a mdx file!`}</p>
    <hr></hr>

    <h2 {...{
      "id": "material-ui-button"
    }}>{`Material UI Button`}</h2>
    <Playground __position={0} __code={'<Button variant=\"contained\" color=\"primary\">\n  Hello World\n</Button>'} __scope={{
      props,
      DefaultLayout,
      Button,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button variant="contained" color="primary" mdxType="Button">
    Hello World
  </Button>
    </Playground>
    <h2 {...{
      "id": "ordered-lists"
    }}>{`Ordered Lists`}</h2>
    <ol>
      <li parentName="ol">{`First Item`}</li>
      <li parentName="ol">{`Next Item`}</li>
      <li parentName="ol">{`Last Item`}</li>
    </ol>
    <h2 {...{
      "id": "unordered-lists"
    }}>{`Unordered Lists`}</h2>
    <ul>
      <li parentName="ul">{`First thing`}</li>
      <li parentName="ul">{`Another thing`}</li>
      <li parentName="ul">{`Last thing`}</li>
    </ul>
    <h2 {...{
      "id": "task-lists"
    }}>{`Task Lists`}</h2>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`I did this one already`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Still need to do this one`}</li>
    </ul>
    <h2 {...{
      "id": "quotes"
    }}>{`Quotes`}</h2>
    <p>{`One of my favorite quotes is`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Don't quote me on that"`}</p>
    </blockquote>
    <h2 {...{
      "id": "code-snippets"
    }}>{`Code Snippets`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function deploy(name: string) {
    console.log(\`Deploying \${name}...\`);
}
`}</code></pre>
    <h2 {...{
      "id": "images"
    }}>{`Images`}</h2>
    <p><img alt="zeit logo" src="https://assets.zeit.co/image/upload/front/assets/design/zeit-black-full-logo.svg" /></p>
    <img src='https://cvm.chenxixian.cn:4000/imgs/2020/04/bd25579ad3207186.jpg' width="500" height="333" />
    <h2 {...{
      "id": "hyperlinks"
    }}>{`Hyperlinks`}</h2>
    <p>{`Visit `}<a parentName="p" {...{
        "href": "https://zeit.co/now"
      }}>{`ZEIT Now`}</a>{` to create your global serverless deployments!`}</p>
    <p>{`Read more about deploying markdown with the `}<a parentName="p" {...{
        "href": "https://zeit.co/docs/v2/deployments/official-builders/markdown-now-md"
      }}>{`@now/md`}</a>{` builder.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      